* {
  box-sizing: border-box;
}

.no-outline:focus {
  outline: none;
}
.hoverPointer:hover {
  cursor: pointer;
}

.paginationDashboardContainer .MuiPaginationItem-rounded {
  /* background-color: #fff !important; */
  border: 2px solid #fff !important;
  color: #fff !important;
}
.paginationDashboardContainer .Mui-selected {
  background-color: #fff !important;
  /* border: 2.3px solid black !important; */
  color: black !important;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
}

.horizontal-scrollbar ::-webkit-scrollbar {
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #359db677;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #359db6;
}

.custom-date-picker .rmdp-input {
  background-color: transparent !important;
  border: none !important;
  /* border-bottom: 1px solid black !important; */
  border-radius: 0 !important;
  width: 100% !important;
  font-size: 15px;
  height: 28px;
  color: #333;
}
.custom-date-picker .rmdp-container {
  width: 100% !important;
}
.custom-date-picker .rmdp-wrapper {
  background-color: #fff !important;
}
.custom-date-picker .rmdp-input:focus {
  box-shadow: none;
}

.avoid-autofill-color-359DB6 input:-internal-autofill-selected {
  background-color: #359db6 !important;
}

.hide-input-bottom-border-container .MuiInput-underline:before,
.hide-input-bottom-border-container .custom-date-picker .rmdp-input {
  border-bottom: none !important;
}

.hide-input-bottom-border-container .MuiInput-underline:after {
  border-bottom: none !important;
}

.hide-datepicker .react-datepicker-wrapper {
  display: none;
}

.hide-datepicker .react-datepicker__tab-loop {
  position: relative;
  min-width: 300px !important;
}

.hide-datepicker .react-datepicker-popper {
  transform: unset !important;
}

.hide-datepicker
  .react-datepicker-popper[data-placement^='bottom']
  .react-datepicker__triangle::after,
.hide-datepicker
  .react-datepicker-popper[data-placement^='bottom']
  .react-datepicker__triangle::before {
  display: none !important;
}
