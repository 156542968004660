.navbar {
  /* background: linear-gradient(90deg, rgb(28, 27, 27) 0%, rgb(26, 23, 23) 100%); */
  background: #eef7fa;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  position: fixed;
  top: 0;
  z-index: 999;
  width: 100vw;
  border-bottom: 1px solid rgb(27, 26, 26);
}

.navbar.active {
  background: #eef7fa;
  transition: 0.4s ease-in-out;
}

.navbar-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  width: 100vw;
  /* max-width: 1500px; */
}

.navbar-logo {
  color: #fff;
  justify-self: start;
  margin-left: 20px;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  display: flex;
  align-items: center;
}

.fa-typo3 {
  margin-left: 0.5rem;
  font-size: 1.8rem;
}

.nav-menu {
  display: grid;
  grid-template-columns: repeat(9, auto);
  grid-gap: 2px;
  list-style: none;
  text-align: center;
  width: 70vw;
  align-items: end;
  justify-content: end;
}

.nav-item {
  height: 14px;
  position: relative;
  /* margin-left: 1.5rem; */

  /* text-align: right; */
}

.nav-links {
  position: relative;
  color: black;
  display: flex;
  font-size: 1.2rem;
  font-weight: 700;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 0.8rem;
  height: 95%;
}

.nav-links::before {
  content: '';
  position: absolute;
  width: 100%;
  bottom: -35%;
  left: 0;
  padding: 0 0 4px;
  border-bottom: 2px solid #359db6;
  transform: scaleX(0);
  /* transform-origin: top left; */
  transition: all 0.3s ease-out;
}
.nav-links:hover::before {
  transform: scaleX(1);
}
.fa-bars {
  color: #1d1d1d;
}

.nav-links-mobile {
  display: none;
}

.menu-icon {
  display: none;
}
/* div {
 color: #fff;
 font-family: Tahoma, Verdana, Segoe, sans-serif;
 padding: 1px;
} */
.container {
  background-color: #2e4272;
  border-bottom: 1px solid #637a80;
  border-bottom: 50%;

  display: flex;
}
.fixed {
  background-color: #eef7fa;
  width: 200px;
  padding-left: 7vw;
  border: 1px;
  border-color: #242424;
}
.flex-item {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  width: 100vw;
  background-color: #eef7fa;
  flex-grow: 1;
}

@media screen and (max-width: 900px) {
  .navbar {
    height: 100px;
  }

  .NavbarItems {
    position: relative;
    /* justify-content: space-between; */
  }

  .nav-item {
    /* margin-right: 1rem; */
    width: 100%;
    text-align: end;
    height: auto;
  }

  .nav-item:not(:last-child) {
    height: fit-content;
    box-sizing: border-box;
    border-bottom: 1px solid white;
  }
  ul.nav-menu .nav-item {
    padding: 1rem;
    padding-right: 2.3rem;
  }
  ul.nav-menu .nav-item:first-child {
    padding-top: 1.5rem;
  }
  ul.nav-menu .nav-item:last-child {
    padding-bottom: 1.2rem;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    padding: 0;
    grid-gap: 2px;
    /* width:60vw; */
    /* height: 67vh; */
    position: absolute;
    top: 75px;
    right: -100%;
    opacity: 8;
    transition: all 0.5s ease;
    justify-content: end;
    align-items: end;
  }

  .nav-menu.active {
    display: flex;
    align-items: flex-end;
    height: fit-content;
    box-sizing: border-box;
    background: #155b6b;
    right: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
    border-top-left-radius: 2rem;
    border-bottom-left-radius: 2rem;
    width: 60vw;

    /* align-items: ; */
  }

  a.nav-links {
    font-size: 1rem;
    text-align: end;
    padding: 0;
    /* padding-right: 3rem; */
    width: 100%;
    display: table;
  }

  .nav-item:hover {
    background-color: transparent;
    color: #fff;
    border-radius: 0;
  }
  .nav-item:first-child:hover {
    border-top-left-radius: 2rem !important;
    background-color: #1d1d1d;
    color: #fff;
    border-radius: 0;
  }
  .nav-item:last-child:hover {
    border-bottom-left-radius: 2rem !important;
    background-color: #1d1d1d;
    color: #fff;
    border-radius: 0;
    width: 100%;
  }

  .navbar-logo {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(15%, 25%);
  }

  .menu-icon {
    display: block;
    align-items: center;
    position: absolute;
    top: 0px;
    right: -8px;
    transform: translate(-100%, 55%);
    font-size: 1.8rem;
    cursor: pointer;
    height: 55px;
  }

  .fa-times {
    color: #1d1d1d;
    font-size: 2rem;
  }

  .nav-links-mobile {
    display: flex;
    text-align: center;
    justify-content: center;
    /* margin: 2rem auto 2rem 1rem; */
    margin: 1rem auto 2rem auto;
    /* margin-right: 3rem; */
    border-radius: 4px;
    width: 50%;
    text-decoration: none;
    font-size: 1.5rem;
    background-color: transparent;
    color: #fff;
    padding: 14px 20px;
    border: 1px solid #fff;
    transition: all 0.3s ease-out;
  }

  .nav-links {
    color: #fff;
    /* position: relative; */
  }
  .nav-links::before {
    border-bottom: none;
    transform: none;
    transform-origin: none;
  }
  .nav-links-mobile:hover {
    background: #fff;
    /* color: #242424; */
    transition: 250ms;
  }
  .submenu {
    /* position: relative; */
    /* width: 50%; */
    /* text-align: end; */
    height: auto;
    word-wrap: break-word;
  }
  .submenuItems {
    display: none;
    position: absolute;
    /* width: 40%; */
    top: 100%;
    left: 10;
    background: #f0f0f0;
  }

  .submenu:hover .submenuItems {
    display: block;
  }
}
